@import url(https://fonts.googleapis.com/css2?family=Cousine&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Cousine', monospace;
    line-height: 1.4;
    color: #333;
    background-color: #f4f4f4;
}

.landing-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 60px;
}

.landing-content {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px;
    box-sizing: border-box;
    text-align: center;
}

.landing-header {
    margin-bottom: 40px;
}

.site-icon {
    width: 80px;
    height: auto;
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.2;
}

.highlight-prd {
    color: #4AA5FF;
}

.tagline {
    font-size: 1.3rem;
    margin-bottom: 40px;
    line-height: 1.6;
}

.subscribe-section {
    margin-bottom: 40px;
}

.subscribe-message {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 15px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.subscribe-form {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.subscribe-form input[type="email"] {
    padding: 12px 15px;
    font-size: 1rem;
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    outline: none;
}

.subscribe-form button {
    padding: 12px 20px;
    font-size: 1rem;
    background-color: #FF6B00;
    color: #fff;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.subscribe-form button:hover {
    background-color: #E65000;
}

.features {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.features h2 {
    font-size: 1.5rem;
    margin-bottom: 25px;
    text-align: center;
}

.features ul {
    list-style-type: none;
    padding-left: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    text-align: left;
}

.features li {
    margin-bottom: 15px;
    padding-left: 35px;
    position: relative;
    page-break-inside: avoid;
    break-inside: avoid;
    line-height: 1.5;
}

.features li::before {
    content: '✓';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #2ecc71;
    font-size: 24px;  
    font-weight: bold;
    line-height: 1;
}

.success-message {
    color: #4CAF50;
    font-weight: bold;
}

.error-message {
    color: #ff6b6b;
    font-weight: bold;
}

@media (max-width: 600px) {
    h1 {
        font-size: 2rem;
    }

    .tagline {
        font-size: 1.2rem;
    }

    .features ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }

    .subscribe-form input[type="email"] {
        width: 200px;
    }
}

.site-footer {
    background-color: #f4f4f4;
    color: #666;
    text-align: center;
    padding: 20px;
    font-size: 0.9rem;
    margin-top: auto;
}

.landing-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.landing-content {
    flex: 1 1;
}
.banner {
  background-color: #4AA5FF;
  color: white;
  text-align: center;
  padding: 8px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  font-size: 0.9rem;
  font-weight: bold;
}

