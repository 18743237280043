.banner {
  background-color: #4AA5FF;
  color: white;
  text-align: center;
  padding: 8px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  font-size: 0.9rem;
  font-weight: bold;
}
